import React from "react"
import Slider from "react-slick"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

export default function PartnerOrganisation() {
  const imageSliderSettings = {
    // fade: true,
    infinite: true,
    // speed: 500,
    // autoplay: true,
    // autoplaySpeed: 4000,
    // arrows: true,
    lazyLoad: true,
    // pauseOnHover: false,
    // cssEase: "linear",
    centerMode: true,
    // dots: true,
    // slidesToShow: 3,
    slidesToScroll: 1,
  }

  const sources = useStaticQuery(graphql`
    query PartnerOrganisationImages {
      allFile(filter: { relativeDirectory: { eq: "dawn/partners" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
            name
          }
        }
      }
    }
  `)

  return (
    <div
      className="display-flex-1 center-content"
      style={{ flexDirection: "row" }}
    >
      {sources.allFile.edges.map(({ node }, index) => (
        <div key={`index${index}`}>
          <Img style={{ width: "360px" }} fluid={node.childImageSharp.fluid} />
        </div>
      ))}
    </div>
  )
}
