import React, { Component } from "react"
import Layout from "../../components/layout"
import Slider from "react-slick"
import ProjectAmbitions from "../../components/dawn/project-ambitions"
import ProjectAdvisors from "../../components/dawn/project-advisors"
import PartnerOrganisation from "../../components/dawn/partner-organisations"
import ImageGrid from "../../components/dawn/image-grid"
import "./dawn.css"

// import "./home-video.css"
const vetConnectIcon = require("./../../images/dawn/icons/vetConnectIcon.png")
const medicineIcon = require("./../../images/dawn/icons/medicineIcon.png")
const milkBottlesIcon = require("./../../images/dawn/icons/milkBottlesIcon.png")
const communityIcon = require("./../../images/dawn/icons/communityIcon.png")
const insuranceAndFinanceIcon = require("./../../images/dawn/icons/insuranceAndFinanceIcon.png")
const cattleTradingIcon = require("./../../images/dawn/icons/cattleTradingIcon.png")
const mooofarmLogo = require("./../../images/dawn/logo/mooofarmLogo.png")
const phoneImage = require("./../../images/dawn/phone.png")

export default function Dawn() {
  const projectAdvisorsSliderSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    // dotsClass: "solution-slider-dots",
    // nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    // prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const offeringsTextPlusIcon = (
    line1,
    line2,
    iconSource,
    isTextUp,
    isCommunity = false
  ) => {
    let comp

    if (isCommunity) {
      comp = (
        <div>
          <div style={{ position: "relative", marginBottom: "20px" }}>
            <div>
              <p className="mooofarm-offering-text">{line1}</p>
              <p className="mooofarm-offering-text">{line2}</p>
            </div>

            <div style={{ position: "relative" }}>
              <div className="commerce-vector-text-left">
                <p
                  style={{
                    width: "140px",
                    textAlign: "center",
                    lineHeight: 1,
                  }}
                  className="infoText"
                >
                  Knowledge Dissemination
                </p>
              </div>
              <div className="commerce-vector commerce-vector-left"></div>
            </div>

            <div style={{ position: "relative" }}>
              <div className="commerce-vector-text-right">
                <p
                  style={{
                    width: "80px",
                    textAlign: "center",
                    lineHeight: 1,
                  }}
                  className="infoText"
                >
                  Query Resolution
                </p>
              </div>
              <div className="commerce-vector commerce-vector-right"></div>
            </div>
          </div>

          <div className="project-ambition-ellipse-container">
            <img className="project-ambition-icon" src={iconSource} />
          </div>
        </div>
      )
      return comp
    }
    isTextUp
      ? (comp = (
          <div>
            <div className="display-flex-1 center-content">
              <div style={{ marginBottom: "10px" }}>
                <p className="mooofarm-offering-text">{line1}</p>
                <p className="mooofarm-offering-text">{line2}</p>
              </div>
              <div className="project-ambition-ellipse-container">
                <img className="project-ambition-icon" src={iconSource} />
              </div>
            </div>
          </div>
        ))
      : (comp = (
          <div className="display-flex-1 center-content">
            <div
              className="project-ambition-ellipse-container"
              style={{ marginBottom: "10px" }}
            >
              <img className="project-ambition-icon" src={iconSource} />
            </div>
            <div>
              <p className="mooofarm-offering-text">{line1}</p>
              <p className="mooofarm-offering-text">{line2}</p>
            </div>
          </div>
        ))
    return comp
  }

  return (
    <Layout>
      <ImageGrid />
      <div style={{ paddingTop: "4rem" }}>
        <p
          className="infoText"
          style={{ textAlign: "center", marginBottom: "10px" }}
        >
          Making Dairy Farming
        </p>
        <h1 className="header-project-goal">
          INCLUSIVE, SUSTAINABLE, EFFICIENT & NUTRITIOUS
        </h1>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <div className="grass-image-background">
          <div>
            <p className="FOR">FOR</p>
            <p className="MILLION">1 MILLION</p>
            <p className="WOMAN-DAIRY-FARMER">WOMEN DAIRY FARMERS</p>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "4rem 0rem",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="container-heading-wrapper ">
          <div className="container-heading-line"></div>
          <p className="container-heading">
            PROJECT <span className="heading-bold-text">OBJECTIVES</span>
          </p>
        </div>
        <div style={{ marginTop: "60px" }}>{ProjectAmbitions()}</div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <p className="container-heading">
            WOMEN ARE THE BACKBONE{" "}
            <span className="heading-bold-text">
              OF INDIA’S DAIRY ECOSYSTEM
            </span>
          </p>
        </div>
        <div style={{ marginTop: "60px", display: "flex" }}>
          <div className="center-content display-flex-1">
            <div
              className="display-flex-1"
              style={{ justifyContent: "flex-end" }}
            >
              <p className="backbone-million-header">100 MILLION</p>
            </div>
            <div className="display-flex-1">
              <p className="Total-Small-holder-Dairy-Farmers">Total</p>
              <p className="Total-Small-holder-Dairy-Farmers">Dairy Farmers</p>
            </div>
          </div>
          <div className="center-content display-flex-1">
            <div className="Group-47"></div>
          </div>
          <div className="center-content display-flex-1">
            <div
              className="display-flex-1"
              style={{ justifyContent: "flex-end" }}
            >
              <p className="backbone-million-header backbone-million-header-right">
                75 MILLION
              </p>
            </div>
            <div className="display-flex-1">
              <p className="Total-Small-holder-Dairy-Farmers">Women</p>
              <p className="Total-Small-holder-Dairy-Farmers">Dairy Farmers</p>
            </div>
          </div>
        </div>
      </div>

      <div className="container-relative height-360">
        <div className="rectangle-pink"></div>
        <div className="backbone-stats-content">
          <h1 className="container-heading but">BUT...</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <div className="display-flex-1">
              <div className="display-flex-1">
                <h1 className="percent-heading">{"< 5%"}</h1>
              </div>
              <div className="display-flex-1">
                <p className="percent-description">of rural women</p>
              </div>
              <div className="display-flex-1">
                <p className="percent-description">own a Cattle</p>
              </div>
            </div>
            <div className="display-flex-1">
              <div className="display-flex-1">
                <h1 className="percent-heading">{"< 10%"}</h1>
              </div>
              <div className="display-flex-1">
                <p className="percent-description">of rural women have</p>
              </div>
              <div className="display-flex-1">
                <p className="percent-description">access to Mobile Data</p>
              </div>
            </div>
            <div className="display-flex-1">
              <div className="display-flex-1">
                <h1 className="percent-heading">{"< 30%"}</h1>
              </div>
              <div className="display-flex-1">
                <p className="percent-description">of women participate in</p>
              </div>
              <div className="display-flex-1">
                <p className="percent-description">
                  Digital Financial Transactions
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          padding: "4rem 0rem",
        }}
      >
        <div className="display-flex-1">
          <p className="container-heading" style={{ marginBottom: "60px" }}>
            THIS CONTRIBUTES TO
          </p>
        </div>

        <div style={{ display: "flex", justifyContent: "space-evenly" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              flex: 1,
            }}
          >
            <div className="display-flex-1" style={{ marginBottom: "40px" }}>
              <h1 className="LOW-MILK-OUTPUT">LOW MILK OUTPUT</h1>
            </div>
            <div className="display-flex-1" style={{ marginBottom: "20px" }}>
              <p
                className="project-ambition-header"
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-evenly",
                }}
              >
                4L
              </p>
            </div>
            <div className="display-flex-1">
              <p className="Per-Cow-Per-Day">Per Cattle Per Day</p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              flex: 1,
            }}
          >
            <div className="display-flex-1" style={{ marginBottom: "40px" }}>
              <h1 className="LOW-MILK-OUTPUT">POOR FOOD SUPPLY</h1>
            </div>
            <div className="display-flex-1" style={{ marginBottom: "20px" }}>
              <p
                className="project-ambition-header"
                style={{
                  display: "flex",
                  flex: 1,
                  justifyContent: "space-evenly",
                }}
              >
                189M
              </p>
            </div>
            <div className="display-flex-1">
              <p className="Per-Cow-Per-Day">Struggle With Malnutrition</p>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "20px",
          padding: "4rem 0rem",
        }}
      >
        <div className="center-content">
          <div className="rectangle-dark-pink"></div>
          <div className="connected-commerce-content center-content">
            <div style={{ marginBottom: "20px" }}>
              <p className="Project-DAWN-aims-to-build-connected-commerce-for">
                Project DAWN aims to build connected commerce for
              </p>
            </div>
            <p className="Project-DAWN-aims-to-build-connected-commerce-for text-style-1">
              WOMEN DAIRY FARMERS
            </p>
          </div>
        </div>
      </div>

      <div className="phoneAndServicesContainer">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <div className="path-background"></div>
            <div className={["phone-image-container"]}>
              <img className="phone-image" src={phoneImage}></img>
            </div>
          </div>

          <div className="circle-container-wrapper">
            <div className="circle-container">
              <img
                src={mooofarmLogo}
                className="circle-container-mooofarm-logo"
              />
              <div className="deg270 circle-container-div-top">
                {offeringsTextPlusIcon(
                  "Mooofarm",
                  "Community",
                  communityIcon,
                  true,
                  true
                )}
              </div>

              <div className="deg325 circle-container-div-top">
                {offeringsTextPlusIcon(
                  "Cattle",
                  "Trading",
                  cattleTradingIcon,
                  true
                )}
              </div>

              <div className="deg215 circle-container-div-top">
                {offeringsTextPlusIcon("Vet", "Connect", vetConnectIcon, true)}
              </div>

              <div className="deg150 circle-container-div-bottom">
                {offeringsTextPlusIcon(
                  "Access to",
                  "Markets",
                  milkBottlesIcon,
                  false
                )}
              </div>

              <div className="deg90 circle-container-div-bottom">
                {offeringsTextPlusIcon(
                  "Insurance &",
                  "Finance",
                  insuranceAndFinanceIcon,
                  false
                )}
              </div>

              <div className="deg30 circle-container-div-bottom">
                {offeringsTextPlusIcon(
                  "Feed &",
                  "Medicine",
                  medicineIcon,
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ padding: "4rem 0rem" }}>
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <p className="container-heading">
            ADVISORY <span className="heading-bold-text">BOARD</span>
          </p>
        </div>
        <div
          style={{
            marginTop: "60px",
          }}
        >
          <div className="advisors-slider-wrapper">
            <Slider {...projectAdvisorsSliderSettings}>
              <ProjectAdvisors
                imageSize="300px"
                advisorName="Morgane Danielou"
                imageName="Morgane Danielou"
                advisorTitleLine1="Vice-President of Communications"
                advisorTitleLine2="EmergingAg Inc."
                advisorTitleLine3=""
                advisorTitleLine4=""
              />

              <ProjectAdvisors
                imageSize="300px"
                advisorName="Thomas Bagge Olesen"
                imageName="Thomas Bagge Olesen"
                advisorTitleLine1="Chairman"
                advisorTitleLine2="The Social Capital Fund Management A/S"
                advisorTitleLine3=""
                advisorTitleLine4=""
              />
              <ProjectAdvisors
                imageSize="300px"
                advisorName="Paul Newnham"
                imageName="Paul - Credit Diana Patient"
                advisorTitleLine1="Director of the SDG2 Advocacy Hub Secretariat"
                advisorTitleLine2="Chefs’ Manifesto Coordinator"
                advisorTitleLine3=""
                advisorTitleLine4=""
              />
            </Slider>
          </div>
        </div>
      </div>

      <div>
        <div className="container-heading-wrapper">
          <div className="container-heading-line"></div>
          <p className="container-heading">
            PARTNER <span className="heading-bold-text">ORGANIZATIONS</span>
          </p>
        </div>
        <div style={{ marginTop: "10px" }}>
          <p className="font-size-20-regular-text">
            Empowering the dairy business together
          </p>
        </div>
        <div style={{ marginTop: "30px" }}>
          <PartnerOrganisation />
        </div>
      </div>
    </Layout>
  )
}
