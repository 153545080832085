import React, { Component } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import { useWindowSize } from "../size-hook"
import "./project-advisors.css"

export default function ProjectAdvisors(props) {
  const size = useWindowSize()
  const advisoryBoardImages = useStaticQuery(graphql`
    query AdvisoryBoardImages {
      allFile(filter: { relativeDirectory: { eq: "dawn/advisory" } }) {
        edges {
          node {
            childImageSharp {
              fluid {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
            name
          }
        }
      }
    }
  `)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ marginBottom: "47px" }}>
        <Img
          className="advisor-gatsby-image"
          fluid={
            advisoryBoardImages.allFile.edges.find(
              n => n.node.name === props.imageName
            ).node.childImageSharp.fluid
          }
        ></Img>
      </div>
      <div className="display-flex-1">
        <p className="font-size-30-header" style={{ marginBottom: "10px" }}>
          {props.advisorName}
        </p>
        <p className="advisory-board-title">{props.advisorTitleLine1}</p>
        <p className="advisory-board-title">{props.advisorTitleLine2}</p>
        <p className="advisory-board-title">{props.advisorTitleLine3}</p>
        <p className="advisory-board-title">{props.advisorTitleLine4}</p>
      </div>
    </div>
  )
}
