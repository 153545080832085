import React, { Component } from "react"
import "./project-ambitions.css"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import emissionIcon from "./../../images/dawn/icons/emissionIcon.png"
import financeIcon from "./../../images/dawn/icons/financeIcon.png"
import insuranceIcon from "./../../images/dawn/icons/insuranceIcon.png"
import milkDistributionIcon from "./../../images/dawn/icons/milkDistributionIcon.png"
import womenAmbassdorsIcon from "./../../images/dawn/icons/womenAmbassdorsIcon.png"

export default function ProjectAmbitions() {
  const ambitionsSliderSettings = {
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 3000,
    dotsClass: "solution-slider-dots",
    // nextArrow: <img className="arrow-style" src={nextArrow} alt="arrow" />,
    // prevArrow: <img className="arrow-style" src={prevArrow} alt="arrow" />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  const ambitionsArray = [
    {
      icon: emissionIcon,
      header: "18%",
      descriptionLine1: "Reduction in",
      descriptionLine2: "GHG Emissions",
    },
    {
      icon: insuranceIcon,
      header: "5M",
      descriptionLine1: "Cattle",
      descriptionLine2: "Insured",
    },
    {
      icon: milkDistributionIcon,
      header: "100M",
      descriptionLine1: "Liters of milk distributed",
      descriptionLine2: "to Malnourished People",
    },
    {
      icon: womenAmbassdorsIcon,
      header: "10K",
      descriptionLine1: "Women",
      descriptionLine2: "Ambassadors",
    },
    {
      icon: financeIcon,
      header: "$1B",
      descriptionLine1: "In Financial",
      descriptionLine2: "Transactions",
    },
  ]

  const renderItem = (items, index) => (
    <div className="project-ambition-container">
      <div className="project-ambition-header-container">
        <p className="project-ambition-header">{items.header}</p>
      </div>
      <div style={{ paddingBottom: "1rem" }}>
        <div className="project-ambition-ellipse-container">
          <img src={items.icon} className="project-ambition-icon"></img>
        </div>
      </div>
      <div>
        <p className="project-ambition-description">{items.descriptionLine1}</p>
        <p className="project-ambition-description">{items.descriptionLine2}</p>
      </div>
    </div>
  )

  return (
    <div className="ambitions-slider-wrapper">
      <Slider {...ambitionsSliderSettings}>
        {ambitionsArray.map((item, index) => (
          <div key={`index${index}`}>{renderItem(item, index)}</div>
        ))}
      </Slider>
    </div>
  )
}
