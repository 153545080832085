import React, { Component } from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"
import "./image-grid.css"

export default function ImageGrid() {
  const logoAndDawnImages = useStaticQuery(graphql`
    query logoAndDawnImages1 {
      file(relativePath: { eq: "dawn/logo/ProjectDAWNLogo.png" }) {
        id
        childImageSharp {
          fluid {
            base64
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "dawn/top" } }) {
        edges {
          node {
            childImageSharp {
              fluid(maxWidth: 500) {
                aspectRatio
                base64
                src
                srcSet
                sizes
              }
            }
            name
          }
        }
      }
    }
  `)
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        maxWidth: "1680px",
        marginBottom: "20px",
        padding: "65px 0px 0px",
      }}
    >
      <div className="image-grid-top-row">
        <div className="firstColumn" style={{ flexDirection: "column" }}>
          <Img
            fluid={
              logoAndDawnImages.allFile.edges.find(
                n => n.node.name === "Image_1_1"
              ).node.childImageSharp.fluid
            }
          ></Img>
          <Img
            fluid={
              logoAndDawnImages.allFile.edges.find(
                n => n.node.name === "Image_2_1"
              ).node.childImageSharp.fluid
            }
          ></Img>
        </div>

        <div
          className="midColumn"
          style={{
            display: "flex",
            flexDirection: "column",
            // maxWidth: "440px",
          }}
        >
          <div className="logoContainer">
            <div style={{ width: "100%" }}>
              {/* <p className="infoText">A MoooFarm Initiative</p> */}
              <Img fluid={logoAndDawnImages.file.childImageSharp.fluid}></Img>
            </div>
          </div>
        </div>

        <div className="endColumn" style={{ flexDirection: "column" }}>
          <Img
            fluid={
              logoAndDawnImages.allFile.edges.find(
                n => n.node.name === "Image_1_3"
              ).node.childImageSharp.fluid
            }
          ></Img>
          <Img
            fluid={
              logoAndDawnImages.allFile.edges.find(
                n => n.node.name === "Image_2_3"
              ).node.childImageSharp.fluid
            }
          ></Img>
        </div>
      </div>

      <div className="image-grid-bottom-row">
        <div className="firstColumn" style={{ flexDirection: "column" }}>
          <Img
            fluid={
              logoAndDawnImages.allFile.edges.find(
                n => n.node.name === "Image_3_1"
              ).node.childImageSharp.fluid
            }
          ></Img>
        </div>

        <div
          className="midColumn"
          style={{
            display: "flex",
            flexDirection: "column",
            // maxWidth: "440px",
          }}
        >
          <div>
            <Img
              fluid={
                logoAndDawnImages.allFile.edges.find(
                  n => n.node.name === "Image_3_2"
                ).node.childImageSharp.fluid
              }
            ></Img>
          </div>
        </div>

        <div className="endColumn" style={{ flexDirection: "column" }}>
          <Img
            fluid={
              logoAndDawnImages.allFile.edges.find(
                n => n.node.name === "Image_3_3"
              ).node.childImageSharp.fluid
            }
          ></Img>
        </div>
      </div>
    </div>
  )
}
